import React from "react";
import "./App.scss";
import { Route, Switch } from "react-router-dom";

import Main from "./components/Main";
import CentreHealth from "./components/CentreHealth";
import withAppInsights from "./AppInsights";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route
          exact
          path="/examples/CentreHealth/datacollection"
          component={CentreHealth}
        />
        <Route path="/examples/CentreHealth" component={CentreHealth} />
      </Switch>
    </div>
  );
}

export default withAppInsights(App);
