import { createReducer } from "@reduxjs/toolkit";
import {
  DebugInfoSuccess,
  FeedbackCollectionSuccess,
} from "../actions/DebugAction";

const INITIAL_STATE = {
  isDebugEnabled: false,
  isScore: true,
  isConfidence: true,
  isFeedbackCollectionEnabled: false,
};

export default createReducer(INITIAL_STATE, {
  [DebugInfoSuccess.type]: (state, action) => {
    if (action.payload) {
      const { debugValue } = action.payload;
      return {
        ...state,
        isDebugEnabled: debugValue,
      };
    }
  },
  [FeedbackCollectionSuccess.type]: (state, action) => {
    if (action.payload) {
      const { feedbackCollectionValue } = action.payload;
      return {
        ...state,
        isFeedbackCollectionEnabled: feedbackCollectionValue,
      };
    }
  },
});
