import { createAction } from "@reduxjs/toolkit";
import { getAutoSuggestions } from "../api/service";
export const AutoSuggestSuccess = createAction<Object>("AutoSuggest/Success");
export const AutoSuggestFail = createAction<Object>("AutoSuggest/Fail");

export const getAutoSuggestQuestions = (
  apiKey: string,
  inputText: string,
  ageFilter?: string
) => {
  return async function (dispatch) {
    try {
      let topicList = await getAutoSuggestions(apiKey, inputText, ageFilter);
      dispatch(AutoSuggestSuccess(topicList));
    } catch (error) {
      dispatch(AutoSuggestFail(error));
      throw error;
    }
  };
};
