import React, { Component } from "react";

import "./Advertisement.scss";

class Advertisement extends Component {
  render() {
    return (
      <div className="Advertisement">
        <img src="/AdvertisementImage.svg" alt="" />
        <div className="ad-text">
          Supporting the next
          <br /> Health Breakthrough
        </div>
        <div className="btn">Give Now</div>
        <div className="logo clearfix">
          <img className="mr-2" src="/MainIcon.png" alt="" />
          <div className="align-middle" style={{ display: "inline-block" }}>
            <h3>Centre Health</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Advertisement;
