import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import { getTopicsList } from "../../actions/TopicsActions";
import AutoSuggestInput from "./AutoSuggestInput";
import { sendAutoSuggestFeedback } from "../../actions/FeedbackAction";
import { FeedbackType } from "./Feedback";

import "./QuestionAnswerForm.scss";
import FormGroup from "react-bootstrap/esm/FormGroup";

interface QuestionAnswerFormState {
  inputText: string;
  textError: boolean;
}

interface TopicList {
  (apiKey: string, inputText: string): Promise<any>;
}

interface SetState {
  (value: boolean): void;
}

interface SetErrorState {
  (value: boolean, text: String): void;
}

interface AutoSuggestFeedback {
  (
    apikey: string,
    responseId: string,
    selectedRank: string,
    displayCount: string,
    feedback: FeedbackType
  ): Promise<any>;
}
interface QuestionAnswerFormProps {
  apiKey: string;
  isError: boolean;
  isApiKeyError: boolean;
  getTopicsList: TopicList;
  setLoadingState: SetState;
  setErrorState: SetErrorState;
  setApiKeyError: SetState;
  sendAutoSuggestFeedback: AutoSuggestFeedback;
}
class QuestionAnswerForm extends Component<
  QuestionAnswerFormProps,
  QuestionAnswerFormState
> {
  state = {
    inputText: undefined,
    textError: false,
  };

  handleChange = (
    selected: string,
    selectedRank: string,
    responseId: string
  ) => {
    // api call for autosuggest feedback
    const { apiKey } = this.props;
    this.props.sendAutoSuggestFeedback(
      apiKey,
      responseId,
      selectedRank,
      "5",
      FeedbackType.autosuggest_selected
    );
    this.setState(
      {
        textError: this.validateInput(selected),
        inputText: selected,
      },
      () => {
        this.onSubmit();
      }
    );
  };

  handleInputChange = (text: string) => {
    this.setState({
      textError: this.validateInput(text),
      inputText: text,
    });
  };

  onEnter = (selected: string) => {
    if (this.state.inputText !== selected) {
      this.setState(
        {
          textError: this.validateInput(selected),
          inputText: selected,
        },
        () => {
          this.onSubmit();
        }
      );
    } else {
      this.onSubmit();
    }
  };

  onSubmit = async (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    const {
      setLoadingState,
      getTopicsList,
      setErrorState,
      apiKey,
    } = this.props;
    if (this.validateInput(this.state.inputText)) {
      this.setState({ textError: true });
    } else if (this.validateApiKey(this.props.apiKey)) {
      this.props.setApiKeyError(true);
    } else {
      if (this.props.isError) {
        setErrorState(false, "");
      }
      setLoadingState(true);
      getTopicsList(apiKey, this.state.inputText)
        .then((data) => {
          setLoadingState(false);
        })
        .catch((error) => {
          setLoadingState(false);
          setErrorState(true, error.message);
        });
    }
  };

  validateInput = (inputText: string) => {
    if (inputText === undefined || inputText === "") {
      return true;
    }
    return false;
  };

  validateApiKey = (apiKey: string) => {
    if (apiKey === undefined || apiKey === null || apiKey === "") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    let isInvalid = this.state.textError || this.props.isApiKeyError;
    return (
      <div className="QuestionAnswerForm">
        <h1>Centre Health Q&amp;A</h1>

        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <InputGroup>
              <AutoSuggestInput
                name="question"
                apiKey={this.props.apiKey}
                isInvalid={isInvalid}
                onChange={this.handleChange}
                handleInputChange={this.handleInputChange}
                onEnter={this.onEnter}
                placeholder="Search for providers, services, health info &amp; more"
                required
              ></AutoSuggestInput>
              <InputGroup.Append>
                <Button
                  className="button-settings"
                  variant="primary"
                  onClick={this.onSubmit}
                >
                  <i className="fa fa-search fa-lg"></i>
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getTopicsList: (apiKey: string, inputText: string) =>
      dispatch(getTopicsList(apiKey, inputText)),
    sendAutoSuggestFeedback: (
      apiKey: string,
      responseId: string,
      selectedRank: string,
      displayCount: string,
      feedback: FeedbackType
    ) =>
      dispatch(
        sendAutoSuggestFeedback(
          apiKey,
          responseId,
          selectedRank,
          displayCount,
          feedback
        )
      ),
  };
}

function mapStateToProps({ ApiKey }) {
  return {
    apiKey: ApiKey.key,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(QuestionAnswerForm);
