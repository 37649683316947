import { axiosInstance } from "./axiosInstance";
import ApiError from "../util/ApiError";
import { FeedbackType } from "../components/centrehealth/Feedback";

export const getTopics = async (
  token: string,
  inputText: string,
  ageFilter?: string
) => {
  let params = retrieveParams(token, inputText, ageFilter);
  let requestConfig = {
    url: "/topics/search/qna",
    headers: {
      "Content-Type": "application/json",
    },
    params: params,
  };
  try {
    let response = await axiosInstance.request(requestConfig);
    return response.data;
  } catch (error) {
    throw new ApiError(error);
  }
};

export const getTopicsHtmlContent = async (token: string, topicId: string) => {
  let params = {
    clientKey: token,
    lang: "en",
    format: "html",
  };
  let requestConfig = {
    url: `/patient/education/topics/${topicId}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: params,
  };
  try {
    let response = await axiosInstance.request(requestConfig);
    return response.data;
  } catch (error) {
    throw new ApiError(error);
  }
};
export const getAutoSuggestions = async (
  token: string,
  inputText: string,
  ageFilter?: string
) => {
  let params = {
    clientKey: token,
    question: inputText,
    top: 5,
    ageGroup: ageFilter,
  };
  let requestConfig = {
    url: "/topics/autosuggest/qna",
    headers: {
      "Content-Type": "application/json",
    },
    params,
  };
  try {
    let response = await axiosInstance.request(requestConfig);
    return response.data;
  } catch (error) {
    throw new ApiError(error);
  }
};

const retrieveParams = (
  token: string,
  inputText: string,
  ageFilter?: string
) => {
  if (!ageFilter) {
    return {
      clientKey: token,
      question: inputText,
      lang: "en",
      top: 10,
      source: "01b7db30",
    };
  } else {
    return {
      clientKey: token,
      question: inputText,
      lang: "en",
      top: 10,
      source: "01b7db30",
      ageGroup: ageFilter,
    };
  }
};

export const provideFeedback = async (
  token: string,
  responseId: string,
  topicId: string,
  questionId: string,
  feedback: FeedbackType
) => {
  let data = {
    clientKey: token,
    responseId: responseId,
    topicId: topicId,
    questionId: questionId,
    eventType: feedback,
  };
  let url = `/feedback/qna`;
  let requestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let response = await axiosInstance.post(url, data, requestConfig);
    return response.data;
  } catch (error) {
    console.error("Error providing feedback", new ApiError(error));
  }
};

export const provideAutoSuggestionFeedback = async (
  token: string,
  responseId: string,
  selectedRank: string,
  displayCount: string,
  feedback: FeedbackType
) => {
  let data = {
    clientKey: token,
    responseId: responseId,
    selectedRank: selectedRank,
    displayCount: displayCount,
    eventType: feedback,
  };
  let url = `/feedback/autosuggest`;
  let requestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let response = await axiosInstance.post(url, data, requestConfig);
    return response.data;
  } catch (error) {
    console.error("Error providing feedback", new ApiError(error));
  }
};
