import React, { Component } from "react";
import { connect } from "react-redux";
import Alert from "react-bootstrap/Alert";

import QuestionAnswerForm from "./QuestionAnswerForm";
import LoadingContainer from "./LoadingContainer";
import QuestionAnswerOutput from "./QuestionAnswerOutput";
import AgeFilter from "./AgeFilter";

import "./FAQ.scss";

interface FAQState {
  firstRun: boolean;
  isLoading: boolean;
  isError: boolean;
  isApiKeyError: boolean;
  textError: string;
}

export interface Topic {
  topicTitle: string;
  topicId: string;
  questionId: string;
  question: string;
  questionHref: string;
  score: number;
  rank: number;
  answer: string;
  confidence: string;
  videos?: Array<Object>;
  graphics?: Array<Object>;
}

interface Graphic {
  id: string;
  lang: string;
  type: string;
  subtype: string;
  version: string;
  graphicHtml: string;
}

interface ApikeyError {
  (value: boolean): void;
}
interface FAQProps {
  location?: any;
  topicsList: Topic[];
  relatedTopicList: Topic[];
  htmlContent: string;
  graphics?: Graphic[];
  match?: any;
  apiKey: string;
  isApiKeyError: boolean;
  setApiKeyError: ApikeyError;
}

class FAQ extends Component<FAQProps, FAQState> {
  state = {
    firstRun: true,
    isLoading: false,
    isError: false,
    isApiKeyError: false,
    textError: "",
  };
  setLoadingState = (value: boolean) => {
    this.setState({ isLoading: value, firstRun: false });
  };

  setErrorState = (value: boolean, text: string) => {
    this.setState({ isError: value, textError: text });
  };

  renderRelatedOutput = () => {
    return this.props.relatedTopicList.length > 0 ? (
      <div className="relatedTopic">
        <span style={{ color: "#005B92", margin: "35px 10px 10px 0" }}>
          <b>Related Questions</b>
        </span>
        <QuestionAnswerOutput
          topicsList={this.props.relatedTopicList}
          isRelatedOutput={true}
        />
      </div>
    ) : null;
  };

  renderError = () => {
    if (this.props.isApiKeyError) {
      return <Alert variant="danger">{`Please provide API key`}</Alert>;
    } else {
      if (this.state.textError === "") {
        return <Alert variant="danger">{`Error in processing request`}</Alert>;
      } else {
        return <Alert variant="danger">{this.state.textError}</Alert>;
      }
    }
  };

  render() {
    let isError = this.props.isApiKeyError || this.state.isError;
    let hasResults =
      this.props.topicsList.length > 0 ||
      this.props.relatedTopicList.length > 0;
    return (
      <div className="FAQ">
        <QuestionAnswerForm
          isError={this.state.isError}
          isApiKeyError={this.props.isApiKeyError}
          setLoadingState={this.setLoadingState}
          setErrorState={this.setErrorState}
          setApiKeyError={this.props.setApiKeyError}
        />

        {this.state.firstRun ? (
          <div>
            <h2>What healthcare questions can we help answer today?</h2>
            <p>
              Find answers that can help you learn more about a wide range of
              symptoms, conditions and treatments. Use this information to begin
              evaluating your health and take steps to find the care you need.
              Make sure to contact your physician to ensure that you receive the
              most appropriate advice. If you or someone close to you needs
              emergency care, please call 9-1-1 immediately.
            </p>
          </div>
        ) : null}

        <LoadingContainer loading={this.state.isLoading}>
          {hasResults ? (
            <AgeFilter setLoadingState={this.setLoadingState} />
          ) : null}

          {isError ? (
            this.renderError()
          ) : (
            <>
              <QuestionAnswerOutput
                topicsList={this.props.topicsList}
                defaultActivekey="0"
                isRelatedOutput={false}
              />
              {this.renderRelatedOutput()}
            </>
          )}
        </LoadingContainer>
      </div>
    );
  }
}
function mapStateToProps({ Topics, ApiKey }) {
  return {
    topicsList: Topics.list,
    relatedTopicList: Topics.relatedTopicList,
    title: Topics.title,
    htmlContent: Topics.htmlContent,
    graphics: Topics.graphics,
    apiKey: ApiKey.key,
  };
}
export default connect(mapStateToProps, null)(FAQ);
