import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

interface setModalShow {
  (value: boolean): void;
}
interface PopupProps {
  setPopupState: setModalShow;
  isPopupShow: boolean;
  Title?: string;
  Content: string;
  isVideo: boolean;
}
class Popup extends Component<PopupProps> {
  handleClose = () => {
    this.props.setPopupState(false);
  };

  render() {
    const { Title, Content, isPopupShow, isVideo } = this.props;
    return (
      <Modal
        show={isPopupShow}
        onHide={this.handleClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#038CCF" }}>{Title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isVideo ? (
            <video width="750" height="450" controls autoPlay>
              <source src={Content} type="video/mp4" />
            </video>
          ) : (
            Content
          )}
        </Modal.Body>
      </Modal>
    );
  }
}
export default Popup;
