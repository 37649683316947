import React from "react";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div>
      <h1>TBD</h1>
      <ul>
        <li>
          <Link to={`/examples/centrehealth/`}>Centre Health</Link>
        </li>
      </ul>
    </div>
  );
}

export default Main;
