import { createAction } from "@reduxjs/toolkit";
export const DebugInfoSuccess = createAction<Object>("DebugInfo/Success");
export const FeedbackCollectionSuccess = createAction<Object>(
  "FeedbackCollection/Success"
);

export const enableDebugInfo = (debugValue: boolean) => {
  return async function (dispatch) {
    dispatch(DebugInfoSuccess({ debugValue: debugValue }));
  };
};

export const enableFeedbackCollection = (feedbackCollectionValue: boolean) => {
  return async function (dispatch) {
    dispatch(
      FeedbackCollectionSuccess({
        feedbackCollectionValue: feedbackCollectionValue,
      })
    );
  };
};
