import React, { Component } from "react";
import parse from "html-react-parser";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { connect } from "react-redux";
import Alert from "react-bootstrap/esm/Alert";

import { getTopicContent } from "../../actions/TopicsActions";

import "./TopicContent.scss";
import LoadingContainer from "./LoadingContainer";
import Button from "react-bootstrap/esm/Button";

interface Graphic {
  id: string;
  lang: string;
  type: string;
  subtype: string;
  version: string;
  graphicHtml: string;
}
interface TopicContent {
  (apiKey: string, topicId: string): Promise<any>;
}

interface TopicContentState {
  isTopicError: boolean;
  isLoading: boolean;
}
interface TopicContentProps {
  htmlContent: string;
  graphics: Graphic[];
  getTopicContent: TopicContent;
  apiKey: string;
  topicId: string;
}
class TopicContent extends Component<TopicContentProps, TopicContentState> {
  state = {
    isTopicError: false,
    isLoading: true,
  };

  componentDidMount = () => {
    const { apiKey, topicId } = this.props;
    this.props
      .getTopicContent(apiKey, topicId)
      .then((data) => {
        this.setState({ isTopicError: false, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isTopicError: true, isLoading: false });
      });
  };
  handleGraphClick = (event) => {
    event.preventDefault();
  };

  parseTableContent = (content: string) => {
    return parse(content, {
      trim: true,
    });
  };

  popover = (graphicsResponse: Graphic[], figureid: string) => {
    if (graphicsResponse !== undefined) {
      let matchedGraphic = graphicsResponse.find(
        (graphic) => graphic.id === figureid
      );
      if (matchedGraphic !== undefined) {
        return (
          <Popover className="wkGraphic-popover" id="wkGraphic-popover-topic">
            <Popover.Content>
              {this.parseTableContent(matchedGraphic.graphicHtml)}
            </Popover.Content>
          </Popover>
        );
      }
    }
  };
  replaceHtmlContent = (output: string, graphics: Graphic[]) => {
    return parse(output || "", {
      replace: (domNode) => {
        if (
          domNode.attribs &&
          domNode.attribs.class &&
          domNode.attribs.class.split(" ").includes("utd-graphic")
        ) {
          return (
            <OverlayTrigger
              rootClose={true}
              trigger="click"
              placement="right"
              overlay={this.popover(graphics, domNode.attribs.id)}
            >
              <Button variant="link" onClick={this.handleGraphClick}>
                {domNode.children[0].data}
              </Button>
            </OverlayTrigger>
          );
        }
      },
    });
  };
  parseHtmlResponse = (htmlContent: string, graphics: Graphic[]) => {
    return this.replaceHtmlContent(htmlContent, graphics);
  };

  render() {
    const { htmlContent, graphics } = this.props;
    const { isLoading, isTopicError } = this.state;
    return (
      <LoadingContainer className="TopicContent" loading={isLoading}>
        {isTopicError ? (
          <Alert variant="danger">
            {`Error in Retreiving Content for TopicId:`}
            {this.props.topicId}
          </Alert>
        ) : (
          this.parseHtmlResponse(htmlContent, graphics)
        )}
      </LoadingContainer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTopicContent: (apiKey: string, topicId: string) =>
      dispatch(getTopicContent(apiKey, topicId)),
  };
}

function mapStateToProps({ Topics }) {
  return {
    htmlContent: Topics.htmlContent,
    graphics: Topics.graphics,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TopicContent);
