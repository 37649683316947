import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import ls from "local-storage";
import Button from "react-bootstrap/Button";

import { saveApiKey } from "../../actions/ApikeyAction";

const Input = Form.Control;

interface SaveApiKey {
  (apikey: string): Promise<any>;
}
interface ApiKeyError {
  (value: boolean): void;
}

interface setModalShow {
  (value: boolean): void;
}
interface ApiKeyModalProps {
  apiKey: string;
  saveApiKey: SaveApiKey;
  setApiKeyError: ApiKeyError;
  setApiKeyModalState: setModalShow;
  isApiKeyModalShow: boolean;
}

class ApiKeyModal extends Component<ApiKeyModalProps> {
  handleClose = () => {
    this.props.setApiKeyModalState(false);
  };

  onApiKeyChange = (event) => {
    event.preventDefault();
    ls("key", event.target.value);
    this.props.setApiKeyError(false);
    this.props.saveApiKey(event.target.value);
  };

  render() {
    return (
      <Modal
        show={this.props.isApiKeyModalShow}
        onHide={this.handleClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Authorize</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            type="password"
            name="apiKey"
            placeholder="API key"
            defaultValue={this.props.apiKey}
            onChange={this.onApiKeyChange}
          ></Input>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    saveApiKey: (apiKey: string) => dispatch(saveApiKey(apiKey)),
  };
}
function mapStateToProps({ Topics, ApiKey }) {
  return {
    inputText: Topics.inputText,
    apiKey: ApiKey.key,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyModal);
