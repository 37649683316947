import { createReducer } from "@reduxjs/toolkit";
import { ApikeySuccess } from "../actions/ApikeyAction";
import ls from "local-storage";

const INITIAL_STATE = {
  key: ls("key"),
};

export default createReducer(INITIAL_STATE, {
  [ApikeySuccess.type]: (state, action) => {
    if (action.payload) {
      const { apiKey } = action.payload;
      return {
        ...state,
        key: apiKey,
      };
    } else {
      return state;
    }
  },
});
