import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";

import TopicReducer from "./reducers/TopicsReducer";
import ApikeyReducer from "./reducers/ApiKeyReducer";
import FeedbackReducer from "./reducers/FeedbackReducer";
import DebugReducer from "./reducers/DebugReducer";
import AutoSuggestReducer from "./reducers/AutoSuggestReducer";

const middleware = [...getDefaultMiddleware()];

if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}

const store = configureStore({
  reducer: {
    Topics: TopicReducer,
    ApiKey: ApikeyReducer,
    Feedback: FeedbackReducer,
    Debug: DebugReducer,
    AutoSuggest: AutoSuggestReducer,
  },
  middleware,
});

export default store;
