import { createAction } from "@reduxjs/toolkit";
import { provideFeedback, provideAutoSuggestionFeedback } from "../api/service";
import { FeedbackType } from "../components/centrehealth/Feedback";
export const FeedbackSuccess = createAction<Object>("Feedback/Success");

export const sendFeedback = (
  apiKey: string,
  responseId: string,
  topicId: string,
  questionId: string,
  feedback: FeedbackType
) => {
  return async function (dispatch) {
    let feedbackResponse = await provideFeedback(
      apiKey,
      responseId,
      topicId,
      questionId,
      feedback
    );
    dispatch(FeedbackSuccess(feedbackResponse));
  };
};

export const sendAutoSuggestFeedback = (
  apiKey: string,
  responseId: string,
  selectedRank: string,
  displayCount: string,
  feedback: FeedbackType
) => {
  return async function (dispatch) {
    let feedbackResponse = await provideAutoSuggestionFeedback(
      apiKey,
      responseId,
      selectedRank,
      displayCount,
      feedback
    );
    dispatch(FeedbackSuccess(feedbackResponse));
  };
};
