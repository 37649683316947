import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";

import "./Header.scss";

function Header({ handleLogin, isLoggedIn }) {
  return (
    <header>
      <Container className="banner">
        <Row>
          <Col xl="4" className="logo clearfix">
            <img className="image-set mr-2" src="/MainIcon.png" alt="" />
            <div
              className="logo-text align-middle"
              style={{ display: "inline-block" }}
            >
              <h3>Centre Health</h3>
              <span>Healthcare you can count on</span>
            </div>
          </Col>
          <Col xl="8">
            <Nav className="justify-content-center mt-3">
              <Nav.Item>
                <Nav.Link>Find A Doctor</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>Locations</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>Our Services</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>Immediate Care</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>Patients &amp; Visitors</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Button
                  style={{ backgroundColor: "#038CD0" }}
                  className="btn-md"
                  onClick={handleLogin}
                >
                  {isLoggedIn ? "Logged In" : "Login"}
                </Button>
              </Nav.Item>
              {/* <i
                style={{ color: "#038DC0" }}
                className="fa fa-search fa-lg"
              ></i> */}
            </Nav>
          </Col>
        </Row>
      </Container>
      <div
        style={{
          backgroundImage: `url(/BackgroundImage.png)`,
          borderTop: "1px solid white",
          paddingTop: "50px",
        }}
        className="backgroundImageDiv"
      >
        <Container>
          <Row>
            <Col md={4} className="imagecolorDiv">
              <div
                style={{ color: "white", fontWeight: 200, fontSize: "18px" }}
                className=""
              >
                Our Commitment
              </div>
              <p
                className=""
                style={{ color: "white", fontSize: "28px", fontWeight: 600 }}
              >
                Helping you stay safe, healthy and informed
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              md={{ offset: 4, span: 8 }}
              style={{ backgroundColor: "white" }}
            >
              <p className="ml-3 mt-3">
                We know that well-informed patients lead to better health
                decisions. That's why we offer tools and services to help you
                find the most up-to-date health information available. Careful
                preparation will instill confidence in your decision
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
}

export default Header;
