import React, { Component } from "react";
import { connect } from "react-redux";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import { getTopicsList } from "../../actions/TopicsActions";

import "./AgeFilter.scss";

interface TopicList {
  (apiKey: string, inputText: string, ageFilter: string): Promise<any>;
}

interface LoadingState {
  (value: boolean): void;
}
interface AgeFilterProps {
  apiKey: string;
  inputText: string;
  ageFilter: string;
  getTopicsList: TopicList;
  setLoadingState: LoadingState;
}
class AgeFilter extends Component<AgeFilterProps> {
  handleAgeFilter = async (ageFilter: string) => {
    const { apiKey, inputText, getTopicsList, setLoadingState } = this.props;
    setLoadingState(true);
    try {
      await getTopicsList(apiKey, inputText, ageFilter);
    } finally {
      setLoadingState(false);
    }
  };
  render() {
    return (
      <div className="AgeFilter">
        <ToggleButtonGroup
          type="radio"
          name="ageButtonGroup"
          value={this.props.ageFilter}
          onChange={this.handleAgeFilter}
        >
          <ToggleButton value="" size="sm">
            All
          </ToggleButton>
          <ToggleButton value="adult" size="sm">
            Adult{" "}
          </ToggleButton>
          <ToggleButton value="pediatric" size="sm">
            Pediatric
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTopicsList: (apiKey: string, inputText: string, ageFilter: string) =>
      dispatch(getTopicsList(apiKey, inputText, ageFilter)),
  };
}

function mapStateToProps({ Topics, ApiKey }) {
  return {
    inputText: Topics.inputText,
    apiKey: ApiKey.key,
    ageFilter: Topics.ageFilter,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AgeFilter);
