import React from "react";
import Loader from "./Loader";
import "./LoadingContainer.scss";

function LoadingContainer({ className = "", children, loading = false }) {
  return (
    <div className={className}>
      {loading ? (
        <div className="LoadingContainer">
          <Loader />
        </div>
      ) : (
        children
      )}
    </div>
  );
}

export default LoadingContainer;
