import React, { Component } from "react";
import { connect } from "react-redux";

import { AsyncTypeahead } from "react-bootstrap-typeahead";

import { getAutoSuggestQuestions } from "../../actions/AutoSuggestAction";

import "./AutoSuggestInput.scss";

interface AutoSuggestQuestions {
  (apikey: string, query: string): Promise<any>;
}
interface InputChange {
  (text: String): void;
}
interface HandleChange {
  (text: String, rank: string, responseId: string): void;
}
interface AutoSuggestState {
  isLoading: boolean;
  options: Array<Object>;
  errorMessage: string;
}
interface AutoSuggestProps {
  apiKey: string;
  onEnter: InputChange;
  handleInputChange: InputChange;
  onChange: HandleChange;
  getAutoSuggestQuestions: AutoSuggestQuestions;
  isInvalid: boolean;
  placeholder: string;
  required: boolean;
  name: string;
  questions?: Array<Object>;
}
export class AutoSuggestInput extends Component<
  AutoSuggestProps,
  AutoSuggestState
> {
  state = {
    isLoading: false,
    options: [],
    errorMessage: "",
  };

  setAutoSuggestState = (
    value: boolean,
    options: Array<Object>,
    message: string
  ) => {
    this.setState({
      isLoading: value,
      options: options,
      errorMessage: message,
    });
  };

  typeAheadRef = React.createRef<AsyncTypeahead>();

  inputChange = (text: string, event: Event) => {
    this.props.handleInputChange(text);
  };
  onKeyDown = async (event: React.KeyboardEvent) => {
    const { onEnter } = this.props;
    if (onEnter && event.key === "Enter") {
      this.typeAheadRef.current.hideMenu();
      // activeIndex === -1 means no selection
      if (this.typeAheadRef.current.state.activeIndex === -1) {
        onEnter(this.typeAheadRef.current.getInput().value);
      }
    }
  };

  doOnChange = (values: Array<any>) => {
    if (values.length > 0) {
      this.props.onChange(
        values[0].question,
        values[0].rank,
        values[0].responseId
      );
    }
  };

  handleSearch = async (query: string) => {
    this.setAutoSuggestState(true, [], "");
    try {
      await this.props.getAutoSuggestQuestions(this.props.apiKey, query);
      this.setAutoSuggestState(false, this.props.questions, "");
      this.props.handleInputChange(query);
    } catch (error) {
      this.setAutoSuggestState(false, ["error"], error.message);
      this.props.handleInputChange(query);
    }
  };

  filterBy = () => true;
  render() {
    const { isInvalid, placeholder, required } = this.props;
    const { isLoading, options, errorMessage } = this.state;
    return (
      <AsyncTypeahead
        ref={this.typeAheadRef}
        className="AutoSuggestInput"
        placeholder={placeholder}
        isInvalid={isInvalid}
        required={required}
        onChange={this.doOnChange}
        filterBy={this.filterBy}
        id="AutoSuggestInput"
        isLoading={isLoading}
        labelKey="question"
        minLength={3}
        onSearch={this.handleSearch}
        options={options}
        emptyLabel={null}
        onKeyDown={this.onKeyDown}
        onInputChange={this.inputChange}
        renderMenuItemChildren={(option, props) => (
          <>
            {errorMessage !== "" ? (
              <span className="text-danger">{errorMessage}</span>
            ) : (
              <>
                <span>{option.question}</span>
                <br />
                <i>Article: {option.topicTitle}</i>
              </>
            )}
          </>
        )}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAutoSuggestQuestions: (apiKey: string, query: string) =>
      dispatch(getAutoSuggestQuestions(apiKey, query)),
  };
}

function mapStateToProps({ AutoSuggest }) {
  return {
    questions: AutoSuggest.questions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoSuggestInput);
