import React, { Component } from "react";
import { connect } from "react-redux";

import { Topic } from "./FAQ";

enum ConfidenceLabel {
  H = "High",
  M = "Medium",
  L = "Low",
}
interface QuestionAnswerDebugInfoProps {
  output: Topic;
  isScore?: boolean;
  isConfidence?: boolean;
  isDebugEnabled: boolean;
}
export class QuestionAnswerDebugInfo extends Component<
  QuestionAnswerDebugInfoProps
> {
  render() {
    return (
      <span>
        {this.props.isDebugEnabled ? (
          <>
            <span>
              {this.props.isScore ? (
                <>
                  <i style={{ color: "grey" }}>Score:</i>{" "}
                  <i style={{ color: "green" }}>{this.props.output.score}</i>
                </>
              ) : null}
            </span>
            <span className="ml-5">
              {this.props.isConfidence ? (
                <>
                  <i style={{ color: "grey" }}>Confidence:</i>{" "}
                  <i style={{ color: "green" }}>
                    {ConfidenceLabel[this.props.output.confidence]}
                  </i>
                </>
              ) : null}
            </span>
          </>
        ) : null}
      </span>
    );
  }
}
function mapStateToProps({ Debug }) {
  return {
    isScore: Debug.isScore,
    isConfidence: Debug.isConfidence,
    isDebugEnabled: Debug.isDebugEnabled,
  };
}
export default connect(mapStateToProps, null)(QuestionAnswerDebugInfo);
