import { createReducer } from "@reduxjs/toolkit";
import { FeedbackSuccess } from "../actions/FeedbackAction";

const INITIAL_STATE = {};

export default createReducer(INITIAL_STATE, {
  [FeedbackSuccess.type]: (state, action) => {
    return {
      ...state,
    };
  },
});
