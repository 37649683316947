import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import { connect } from "react-redux";

import QuestionAnswer from "./QuestionAnswer";
import { sendFeedback } from "../../actions/FeedbackAction";
import { FeedbackType, ResultFeedback } from "./Feedback";
import { Topic } from "./FAQ";

interface QuestionAnswerOutputProps {
  topicsList: Topic[];
  apiKey: any;
  defaultActivekey?: string;
  responseId: string;
  sendFeedback: ResultFeedback;
  isRelatedOutput: boolean;
}

interface QuestionAnswerOutputState {
  isDefaultViewed: boolean;
  topicsSelectedKey: string;
  relatedTopicsSelectedKey: string;
  isTopicsAccordionOpen: boolean;
  isRelatedTopicsAccordionOpen: boolean;
}
class QuestionAnswerOutput extends Component<
  QuestionAnswerOutputProps,
  QuestionAnswerOutputState
> {
  state = {
    isDefaultViewed: false,
    topicsSelectedKey: "0",
    relatedTopicsSelectedKey: "",
    isTopicsAccordionOpen: true,
    isRelatedTopicsAccordionOpen: false,
  };

  componentDidMount = () => {
    this.setState({ isDefaultViewed: true });
  };
  setTopicsAccordionState = (key: string, value?: boolean) => {
    this.setState({
      topicsSelectedKey: value ? key : "",
      isTopicsAccordionOpen: value,
    });
  };

  setRelatedTopicsAccordionState = (key: string, value?: boolean) => {
    this.setState({
      relatedTopicsSelectedKey: value ? key : "",
      isRelatedTopicsAccordionOpen: value,
    });
  };
  getOutput = (output: Topic, key: string) => {
    const { sendFeedback, apiKey, responseId, isRelatedOutput } = this.props;
    const {
      isTopicsAccordionOpen,
      isDefaultViewed,
      topicsSelectedKey,
      relatedTopicsSelectedKey,
      isRelatedTopicsAccordionOpen,
    } = this.state;
    // to send feedback for default open view
    if (
      key === "0" &&
      isTopicsAccordionOpen &&
      !isRelatedOutput &&
      !isDefaultViewed
    ) {
      sendFeedback(
        apiKey,
        responseId,
        output.topicId,
        output.questionId,
        FeedbackType.view
      );
    }
    let selectedKey = isRelatedOutput
      ? relatedTopicsSelectedKey
      : topicsSelectedKey;
    let accordionOpenValue = isRelatedOutput
      ? isRelatedTopicsAccordionOpen
      : isTopicsAccordionOpen;
    let setAccordionStateValue = isRelatedOutput
      ? this.setRelatedTopicsAccordionState
      : this.setTopicsAccordionState;
    return (
      <QuestionAnswer
        output={output}
        index={key}
        key={output.questionId}
        selectedKey={selectedKey}
        isAccordionOpen={accordionOpenValue}
        setAccordionState={setAccordionStateValue}
      />
    );
  };

  render() {
    const { topicsList = [] } = this.props;
    return (
      <div className="mt-2">
        <Accordion defaultActiveKey={this.props.defaultActivekey}>
          {topicsList.map((output, key) =>
            this.getOutput(output, key.toString())
          )}
        </Accordion>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    sendFeedback: (
      apiKey: string,
      responseId: string,
      topicId: string,
      questionId: string,
      feedback: FeedbackType
    ) =>
      dispatch(sendFeedback(apiKey, responseId, topicId, questionId, feedback)),
  };
}
function mapStateToProps({ ApiKey, Topics }) {
  return {
    responseId: Topics.responseId,
    apiKey: ApiKey.key,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionAnswerOutput);
