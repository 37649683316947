import { createReducer } from "@reduxjs/toolkit";
import {
  topicsListSuccess,
  topicsListFail,
  topicContentSuccess,
  topicContentFail,
  topicListRequested,
} from "../actions/TopicsActions";

const INITIAL_STATE = {
  list: [],
  inputText: "",
  relatedTopicList: [],
  title: "",
  htmlContent: "",
  graphics: [],
  ageFilter: "",
  responseId: "",
};

export default createReducer(INITIAL_STATE, {
  [topicListRequested.type]: (state, action) => {
    if (action.payload) {
      const { inputText, ageFilter } = action.payload;
      return {
        ...state,
        list: [],
        relatedTopicList: [],
        inputText: inputText,
        ageFilter: ageFilter === undefined ? "" : ageFilter,
      };
    } else {
      return state;
    }
  },
  [topicsListSuccess.type]: (state, action) => {
    if (action.payload) {
      const {
        question,
        totalHits,
        questionsAndAnswers,
        responseId,
      } = action.payload;
      if (questionsAndAnswers) {
        let relatedTopicList = questionsAndAnswers.filter(
          (topic) => topic.confidence !== "H"
        );
        let topicList = questionsAndAnswers.filter(
          (topic) => topic.confidence === "H"
        );

        return {
          ...state,
          list: topicList,
          relatedTopicList: relatedTopicList,
          inputText: question,
          totalHits: totalHits,
          responseId: responseId,
        };
      }
    } else {
      return state;
    }
  },
  [topicsListFail.type]: (state, action) => {
    return state;
  },
  [topicContentSuccess.type]: (state, action) => {
    if (action.payload) {
      const { topicInfo, topicHtml, graphics } = action.payload;
      return {
        ...state,
        title: topicInfo.title,
        htmlContent: topicHtml,
        graphics: graphics,
      };
    }
  },
  [topicContentFail.type]: (state, action) => {
    return state;
  },
});
