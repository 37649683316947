import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FaPlusCircle } from "react-icons/fa";

import "./Chatbot.scss";

class Chatbot extends Component {
  state = {
    showAccoridion: false,
  };
  handleAccordion = () => {
    this.setState({ showAccoridion: !this.state.showAccoridion });
  };
  render() {
    return (
      <>
        <Button
          className="wk-chatbot-button"
          variant="primary"
          onClick={this.handleAccordion}
        >
          <span>Health Q&A</span>
          <FaPlusCircle size="22" className="ml-1 pb-1" />
        </Button>
        {this.state.showAccoridion ? (
          <Accordion defaultActiveKey="0">
            <Card className="wk-chatbot-container" key="0">
              <Card.Header className="header">
                <Accordion.Toggle as="a" className="row" eventKey="0">
                  <div className="ml-1 mr-5 pr-5">
                    <span style={{ color: "white", fontSize: "20px" }}>
                      Patient ChatBot
                    </span>
                  </div>
                  <div style={{ marginLeft: "110px" }} className="pl-5">
                    <Button size="sm" onClick={this.handleAccordion}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </Button>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <iframe
                    title="PatientChatbot"
                    className="iframe-body"
                    src={process.env.REACT_APP_API_CHATBOT_URL}
                  ></iframe>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : null}
      </>
    );
  }
}
export default Chatbot;
