import { createAction } from "@reduxjs/toolkit";
import { getTopics, getTopicsHtmlContent } from "../api/service";

export const topicsListSuccess = createAction<Array<Object>>(
  "topics/listSuccess"
);
export const topicsListFail = createAction<String>("topics/listFail");
export const topicContentSuccess = createAction<Array<Object>>(
  "topics/ContentSuccess"
);
export const topicContentFail = createAction<String>("topics/ContentFail");
export const topicListRequested = createAction<Object>("topics/ListRequested");
export const getTopicsList = (
  apiKey: string,
  inputText: string,
  ageFilter?: string
) => {
  return async function (dispatch) {
    try {
      dispatch(topicListRequested({ apiKey, inputText, ageFilter }));
      let topicList = await getTopics(apiKey, inputText, ageFilter);
      dispatch(topicsListSuccess(topicList));
    } catch (error) {
      dispatch(topicsListFail(error));
      throw error;
    }
  };
};

export const getTopicContent = (apiKey: string, topicId: string) => {
  return async function (dispatch) {
    try {
      let topicList = await getTopicsHtmlContent(apiKey, topicId);
      dispatch(topicContentSuccess(topicList));
    } catch (error) {
      dispatch(topicContentFail(error));
      throw error;
    }
  };
};
