import React, { Component } from "react";
import { connect } from "react-redux";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";

import { sendFeedback } from "../../actions/FeedbackAction";
import ToggleButtonGroup from "react-bootstrap/esm/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import "./Feedback.scss";

export enum FeedbackType {
  positive = "HELPFUL_ANSWER_YES",
  negative = "HELPFUL_ANSWER_NO",
  view = "VIEW",
  autosuggest_selected = "AUTOSUGGEST_SELECTED",
}
export interface ResultFeedback {
  (
    apikey: string,
    responseId: string,
    topicId: string,
    questionId: string,
    feedback: FeedbackType
  ): Promise<any>;
}
interface FeedbackProps {
  apiKey: string;
  topicId: string;
  questionId: string;
  responseId: string;
  sendFeedback: ResultFeedback;
}

interface FeedbackState {
  selectedFeedback: FeedbackType;
}
export class Feedback extends Component<FeedbackProps, FeedbackState> {
  state = {
    selectedFeedback: undefined,
  };

  handleFeedbackSelection = async (selectedFeedback) => {
    const {
      apiKey,
      responseId,
      topicId,
      questionId,
      sendFeedback,
    } = this.props;
    try {
      await sendFeedback(
        apiKey,
        responseId,
        topicId,
        questionId,
        selectedFeedback
      );
      this.setState({
        selectedFeedback,
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { selectedFeedback } = this.state;
    return (
      <div className="Feedback">
        <ToggleButtonGroup
          type="radio"
          name={`feedback-${this.props.responseId}-${this.props.topicId}-${this.props.questionId}`}
          value={this.state.selectedFeedback}
          onChange={this.handleFeedbackSelection}
        >
          <ToggleButton
            className="Feedback-Positive"
            value={FeedbackType.positive}
            disabled={!!selectedFeedback}
          >
            <FaThumbsUp />
          </ToggleButton>
          <ToggleButton
            className="Feedback-Negative"
            value={FeedbackType.negative}
            disabled={!!selectedFeedback}
          >
            <FaThumbsDown />
          </ToggleButton>
        </ToggleButtonGroup>
        {selectedFeedback ? (
          <span> Thank you for your feedback!</span>
        ) : (
          <span> Did this answer your question?</span>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendFeedback: (
      apiKey: string,
      responseId: string,
      topicId: string,
      questionId: string,
      feedback: FeedbackType
    ) =>
      dispatch(sendFeedback(apiKey, responseId, topicId, questionId, feedback)),
  };
}

function mapStateToProps({ Topics, ApiKey }) {
  return {
    responseId: Topics.responseId,
    apiKey: ApiKey.key,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
