import { createReducer } from "@reduxjs/toolkit";
import {
  AutoSuggestSuccess,
  AutoSuggestFail,
} from "../actions/AutoSuggestAction";

const INITIAL_STATE = {
  questions: [],
  inputText: "",
};

export default createReducer(INITIAL_STATE, {
  [AutoSuggestSuccess.type]: (state, action) => {
    if (action.payload) {
      const { input, autoSuggestQuestions, responseId } = action.payload;
      return {
        ...state,
        questions: updateQuestions(autoSuggestQuestions, responseId),
        inputText: input,
      };
    } else {
      return state;
    }
  },
  [AutoSuggestFail.type]: (state, action) => {
    return state;
  },
});

const updateQuestions = (questions, responseId) => {
  return questions.map((question) =>
    Object.assign(question, { responseId: responseId })
  );
};
