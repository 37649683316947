import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Chatbot from "./centrehealth/Chatbot";
import Advertisement from "./centrehealth/Advertisement";
import ApiKeyModal from "./centrehealth/ApiKeyModal";
import FAQ from "./centrehealth/FAQ";
import TopicContent from "./centrehealth/TopicContent";
import Footer from "./centrehealth/Footer";
import Header from "./centrehealth/Header";
import {
  enableDebugInfo,
  enableFeedbackCollection,
} from "../actions/DebugAction";
import { saveApiKey } from "../actions/ApikeyAction";
import { axiosInstance } from "../api/axiosInstance";

import "./CentreHealth.scss";

interface CentreHealthState {
  isApiKeyError: boolean;
  isApiKeyModalShow: boolean;
}

interface DebugInfo {
  (debugValue: boolean): Promise<any>;
}

interface SaveApiKey {
  (apikey: string): Promise<any>;
}
interface CentreHealthProps {
  location?: any;
  match?: any;
  apiKey: string;
  history?: any;
  enableDebugInfo: DebugInfo;
  enableFeedbackCollection: DebugInfo;
  saveApiKey: SaveApiKey;
  isFeedbackCollectionEnabled: boolean;
}

class CentreHealth extends Component<CentreHealthProps, CentreHealthState> {
  state = {
    isApiKeyError: false,
    isApiKeyModalShow: false,
  };

  componentDidMount = () => {
    let { path } = this.props.match;
    let query = new URLSearchParams(this.props.location.search);
    if (path.includes("datacollection")) {
      // setting QA Base URL for API end-point in case of data collection
      axiosInstance.defaults.baseURL="https://qa-covid19services-api.uptodate.com/";
      this.props.enableFeedbackCollection(true);
      if (query.has("cohort")) {
        let decoded_key = "";
        try {
          decoded_key = atob(query.get("cohort"));
        } catch {
          decoded_key = query.get("cohort");
        }
        this.props.saveApiKey(decoded_key);
      }
    }
    if (query.has("debug")) {
      window.sessionStorage.setItem("EnableDebugInfo", "true");
      this.props.enableDebugInfo(true);
      this.props.history.push(path);
    }
  };

  handleLogin = () => {
    this.setState({ isApiKeyModalShow: true });
  };

  setApiKeyError = (value: boolean) => {
    this.setState({ isApiKeyError: value });
  };

  setApiKeyModalState = (value: boolean) => {
    this.setState({ isApiKeyModalShow: value });
  };

  render() {
    let { path } = this.props.match;
    return (
      <div className="CentreHealth">
        <Header
          handleLogin={this.handleLogin}
          isLoggedIn={!!this.props.apiKey}
        />
        <Container>
          <Row>
            <Col>
              <Switch>
                <Route exact path={path}>
                  <FAQ
                    isApiKeyError={this.state.isApiKeyError}
                    setApiKeyError={this.setApiKeyError}
                  />
                </Route>
                <Route
                  path={`${path}/articles/:topicId`}
                  render={(props) => (
                    <TopicContent
                      apiKey={this.props.apiKey}
                      topicId={props.match.params.topicId}
                    />
                  )}
                />
              </Switch>
            </Col>
            <Col md={3}>
              <Advertisement />
              {!this.props.isFeedbackCollectionEnabled ? <Chatbot /> : null}
            </Col>
          </Row>
        </Container>
        {this.state.isApiKeyModalShow ? (
          <ApiKeyModal
            setApiKeyError={this.setApiKeyError}
            setApiKeyModalState={this.setApiKeyModalState}
            isApiKeyModalShow={this.state.isApiKeyModalShow}
          />
        ) : null}
        <Footer />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    enableDebugInfo: (debugValue) => dispatch(enableDebugInfo(debugValue)),
    enableFeedbackCollection: (feedbackCollectionValue) =>
      dispatch(enableFeedbackCollection(feedbackCollectionValue)),
    saveApiKey: (apiKey: string) => dispatch(saveApiKey(apiKey)),
  };
}

function mapStateToProps({ ApiKey, Debug }) {
  return {
    apiKey: ApiKey.key,
    isFeedbackCollectionEnabled: Debug.isFeedbackCollectionEnabled,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CentreHealth);
