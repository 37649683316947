export default class ApiError extends Error {
  constructor(error) {
    if (error.isAxiosError && error.response) {
      let { httpStatusCode, reason } = error.response.data;
      if (httpStatusCode === "401" || httpStatusCode === "400") {
        super(reason);
      } else {
        super(JSON.stringify(error.response.data));
      }
    } else {
      super(error.message);
    }
  }
}
