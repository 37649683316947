import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import Popover from "react-bootstrap/Popover";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import Button from "react-bootstrap/esm/Button";

import { sendFeedback } from "../../actions/FeedbackAction";
import { FeedbackType, ResultFeedback } from "./Feedback";
import Feedback from "./Feedback";
import Popup from "./Popup";
import QuestionAnswerDebugInfo from "./QuestionAnswerDebugInfo";

import "./QuestionAnswer.scss";
import { Topic } from "./FAQ";
interface AccordionState {
  (key: string, value: boolean): void;
}

interface QuestionAnswerProps {
  index: string;
  output: Topic;
  apiKey: string;
  selectedKey: string;
  isAccordionOpen: boolean;
  setAccordionState: AccordionState;
  responseId: string;
  sendFeedback: ResultFeedback;
}

interface QuestionAnswerState {
  isPopupShow: boolean;
  Content: string;
  Title: string;
  isVideo: boolean;
}

class QuestionAnswer extends Component<
  QuestionAnswerProps,
  QuestionAnswerState
> {
  state = {
    isPopupShow: false,
    Content: "",
    Title: "",
    isVideo: false,
  };
  handleAccordion = (key: string): void => {
    const {
      selectedKey,
      setAccordionState,
      output,
      sendFeedback,
      apiKey,
      responseId,
    } = this.props;
    if (selectedKey === key) {
      setAccordionState(key, false);
    } else {
      setAccordionState(key, true);
      sendFeedback(
        apiKey,
        responseId,
        output.topicId,
        output.questionId,
        FeedbackType.view
      );
    }
  };

  handleGraphClick = (
    graphicsResponse: any,
    figureid: string,
    title: string
  ) => {
    let matchedGraphic = graphicsResponse.find(
      (graphic) => graphic.id === figureid
    );
    if (matchedGraphic !== undefined) {
      this.setState({ isVideo: false });
      this.setPopupState(true);
      this.setModalProps(
        title,
        this.parseGraphicContent(matchedGraphic.graphicHtml)
      );
    }
  };

  parseGraphicContent = (content: string) => {
    return parse(content, {
      trim: true,
    });
  };

  popover = (graphicsResponse: any, figureid: string) => {
    let matchedGraphic = graphicsResponse.find(
      (graphic) => graphic.id === figureid
    );
    if (matchedGraphic !== undefined) {
      return (
        <Popover className="wkGraphic-popover" id="wkGraphic-popover-qna">
          <Popover.Content>
            {this.parseGraphicContent(matchedGraphic.graphicHtml)}
          </Popover.Content>
        </Popover>
      );
    }
  };

  replaceContent = (output) => {
    return parse(output.answer || "", {
      replace: (domNode) => {
        if (
          domNode.attribs &&
          domNode.attribs.class &&
          domNode.attribs.class.split(" ").includes("utd-graphic")
        ) {
          return (
            <Button
              variant="link"
              onClick={() =>
                this.handleGraphClick(
                  output.graphics,
                  domNode.attribs.id,
                  domNode.children[0].data
                )
              }
            >
              {domNode.children[0].data}
            </Button>
          );
        }
      },
    });
  };

  setPopupState = (value: boolean) => {
    this.setState({ isPopupShow: value });
  };

  setModalProps = (title: string, Content: any) => {
    this.setState({ Title: title, Content: Content });
  };

  playVideo = (videoHref: string, title: string) => {
    this.setState({ isVideo: true });
    this.setPopupState(true);
    this.setModalProps(title, videoHref);
  };

  showGraph = (title: string, graph: any) => {
    this.setState({ isVideo: false });
    this.setPopupState(true);
    this.setModalProps(title, this.parseGraphicContent(graph.graphicHtml));
  };
  renderThumbnail = (video) => {
    return (
      <div
        key={video.title}
        className="mr-4 mt-2"
        style={{ display: "inline-grid", width: "min-content" }}
      >
        <img
          src={video["thumbnailHref"]}
          width="150"
          height="100"
          alt={video.title}
          onClick={() => this.playVideo(video["mp4Href"], video.title)}
        ></img>
        <i
          style={{
            color: "#038CCF",
            fontWeight: "bolder",
            textAlign: "center",
          }}
        >
          {video.title}
        </i>
      </div>
    );
  };
  renderGraphics = (graph) => {
    return (
      <div
        key={graph.id}
        className="mr-4 mt-2"
        style={{ display: "inline-grid", width: "min-content" }}
      >
        <img
          src={graph["imageHref"]}
          width="150"
          height="100"
          alt={graph.title}
          onClick={() => this.showGraph(graph.displayNames[0], graph)}
        ></img>
        <i
          style={{
            color: "#038CCF",
            fontWeight: "bolder",
            textAlign: "center",
          }}
        >
          {graph.displayNames[0]}
        </i>
      </div>
    );
  };
  render() {
    const { index, output, selectedKey, isAccordionOpen } = this.props;
    const { Content, Title, isPopupShow } = this.state;
    /* This path would ideally be generated relative to current but tricky here without a functional
       component where we could use the useRouteMatch hook to get current matched path */
    let topicLink = generatePath(`/examples/CentreHealth/articles/:topicId`, {
      topicId: output.topicId,
    });
    return (
      <Card className="QuestionAnswer" key={index}>
        <Card.Header
          className={
            isAccordionOpen
              ? "cardHeaderWithOpenAccordion"
              : "cardHeaderSetting"
          }
        >
          <Accordion.Toggle
            as="a"
            className="row"
            eventKey={index}
            onClick={() => this.handleAccordion(index)}
          >
            <div>
              {selectedKey === index && isAccordionOpen ? (
                <FaMinusCircle color="#038CCF" />
              ) : (
                <FaPlusCircle color="#038CCF" />
              )}
            </div>
            <div className={"col"}>
              <b style={{ color: "#038CCF" }}>{output.question}</b>
              <br />
              <i style={{ color: "grey" }}>Article</i>:{" "}
              <i style={{ color: "green" }}>{output.topicTitle}</i>
              <br />
              <QuestionAnswerDebugInfo output={output} />
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={index}>
          <Card.Body className="mt-2" style={{ marginLeft: "40px" }}>
            {this.replaceContent(output || "")}
            <div>
              <Link className="learn-more" to={topicLink} target="_blank">
                View Full Article
              </Link>
            </div>
            <div style={{ display: "inline" }}>
              {output.graphics
                ? output.graphics.map((graph) => this.renderGraphics(graph))
                : null}
            </div>
            <div style={{ display: "inline" }}>
              {output.videos
                ? output.videos.map((video) => this.renderThumbnail(video))
                : null}
            </div>
            <Feedback topicId={output.topicId} questionId={output.questionId} />
            {this.state.isPopupShow ? (
              <Popup
                Content={Content}
                Title={Title}
                isPopupShow={isPopupShow}
                setPopupState={this.setPopupState}
                isVideo={this.state.isVideo}
              />
            ) : null}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    sendFeedback: (
      apiKey: string,
      responseId: string,
      topicId: string,
      questionId: string,
      feedback: FeedbackType
    ) =>
      dispatch(sendFeedback(apiKey, responseId, topicId, questionId, feedback)),
  };
}
function mapStateToProps({ ApiKey, Topics }) {
  return {
    responseId: Topics.responseId,
    apiKey: ApiKey.key,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(QuestionAnswer);
