import React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light footer">
      <div
        className="col-12"
        style={{
          backgroundColor: "rgba(242,242,242,1)",
          display: "flex",
          marginTop: "15px",
        }}
      >
        <div className="col-7 mt-3 ml-5" style={{ display: "flex" }}>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <span style={{ fontSize: "13px" }}>
              <strong>About Centre Health</strong>
            </span>
            <span style={{ fontSize: "13px" }}>Medical Group</span>
            <span style={{ fontSize: "13px" }}>Careers</span>
            <span style={{ fontSize: "13px" }}>Newsroom</span>
            <span style={{ fontSize: "13px" }}>Community</span>
          </div>
          <div style={{ display: "flex", flexFlow: "column" }} className="ml-4">
            <span style={{ fontSize: "13px" }}>
              <strong>Quick Links</strong>
            </span>
            <span style={{ fontSize: "13px" }}>Physicians</span>
            <span style={{ fontSize: "13px" }}>Employees</span>
            <span style={{ fontSize: "13px" }}>Financial Assistance</span>
            <span style={{ fontSize: "13px" }}>Health Resources</span>
          </div>
          <div style={{ display: "flex", flexFlow: "column" }} className="ml-5">
            <span>&nbsp;</span>
            <span style={{ fontSize: "13px" }}>Gift Shop</span>
            <span style={{ fontSize: "13px" }}>Patient Rights</span>
            <span style={{ fontSize: "13px" }}>
              Notice of Privacy Practices
            </span>
            <span style={{ fontSize: "13px" }}>Site Map</span>
          </div>
          <div style={{ display: "flex", flexFlow: "column" }} className="ml-4">
            <span style={{ fontSize: "13px" }}>
              <strong>Contact Us</strong>
            </span>
            <span style={{ fontSize: "13px" }}>
              1223 Centre Dr.,Centre, MA, 02400
            </span>
            <span style={{ fontSize: "13px" }}>
              Main Hospital -{" "}
              <span style={{ color: "#038CD0", fontSize: "13px" }}>
                <strong>800-555-1212</strong>
              </span>
            </span>
            <span style={{ fontSize: "13px" }}>
              Hospital Billing -{" "}
              <span style={{ color: "#038CD0", fontSize: "13px" }}>
                <strong>800-555-1515</strong>
              </span>
            </span>
            <span style={{ fontSize: "13px" }}>
              Human Resources -{" "}
              <span style={{ color: "#038CD0", fontSize: "13px" }}>
                <strong>800-555-2121</strong>
              </span>
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Footer;
